.stats-count {
  font-size: 20px;
  font-weight: bold;
}

.card-all-data {
  margin-bottom: 20px;
}

.rejected-count {
  text-align: right;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: right;
  color: #28a745;
}
.ts-buttons {
  border-radius: 0;
  margin: 0 10px 0 10px;
  min-width: 75px;
}
.ts-buttons-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
.weekly-progress {
  padding: 2px 5px 2px 7px;
  border-radius: 3px;
  font-size: 13px;
}
.perdaynot {
  font-size: 15px;
  margin-left: 4px;
  font-weight: 500;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.progress {
  border-radius: 0;
}

.flex-responsive {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin: 30px 0 10px 0;
}

@media screen and (max-width: 500px) {
  .flex-responsive {
    display: block;
  }
  .flex-responsive > div {
    width: 100% !important;
    border: none !important;
    padding: 0 !important;
    margin-top: 10px !important;
  }
}

input {
  outline: none !important;
  outline-width: 0 !important;
  outline-style: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
  outline-width: 0 !important;
  outline-style: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

*:focus {
  outline: none;
  outline-style: none;
}

.leaderboard {
  margin-top: 25px;
  border-top: 1px solid #ddd;
  padding-top: 15px;
}

.moduleProgressBar {
  width: 160px;
  height: 160px;
}

.tabTitle {
  text-align: center;
  width: 175px;
}

.page-dashboard .table td,
.page-dashboard .table th {
  padding: 0.21rem !important;
}

/* .page-dashboard .table td {
  font-family: sans-serif;
  font-size: 11px !important;
  text-align: center;
} */

/* .page-dashboard .table th {
  font-size: 10px !important;
} */
/* #usersTable th {
  font-size: 14px !important;
} */

.tabTitleLabel,
.tabTitleLabel:hover {
  color: #007bff !important;
  font-size: 20px;
}
.tabTitleLabel:hover {
  color: green;
}
.disabledTitle,
.disabledTitle:hover {
  color: red !important;
  font-size: 20px;
}

.spanClickable {
  color: #007bff !important;
  cursor: pointer;
  margin-right: 15px;
}

.spanClickable:hover {
  text-decoration: underline;
}

.table_triggers {
  font-size: 14px;
}

.table-responsive {
  overflow-x: visible;
  max-width: 100%;
  /* font-size: 13px; */
}

.dx-g-bs4-table {
  min-width: 0 !important;
  max-width: 100%;
}

.table td,
.table th {
  vertical-align: middle !important;
}

.actionButtonLessPadding {
  padding: 0.175rem 0.65rem !important;
}

.editModal {
  width: 750px;
  max-width: 750px;
}

.input-text-trigger-name,
.input-text-sound-name {
  border: none;
  font-size: 24px;
  border-radius: 0;
}

.input-text-trigger-name:focus,
.input-text-sound-name:focus {
  outline-width: 0;
  outline: none;
  box-shadow: none;
}

.form-control:focus {
  outline-width: 0;
  outline: none;
  box-shadow: none;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.tabletoolkit {
  margin-top: 20px;
}

/* .table td,
.table th {
  font-size: 13px;
} */

.react-bootstrap-table {
  overflow-x: auto !important;
}

/* .dash_table_options_float_right {
  float: right;
} */
.dash_table_options_float_left {
  float: left;
}

.search-bar {
  text-align: left;
}

.dash_table_inputs {
  width: 100%;
  margin: 0 auto;
}

.div-scan-count {
  text-align: center;
  border: 1px solid #ccc;
  margin: 10px 0 10px 0;
  min-width: 175px;
  padding: 10px 5px 10px 5px;
  display: inline-block;
}

/* .div-scan-count:not(:last-child) {
  margin-right: 20px;
} */

.div-scan-count > .scan-count {
  font-size: 32px;
  border-top: 1px solid #38383d;
  margin-top: 20px;
}

.div-scan-count > .reject-count {
  font-size: 14px;
  color: #f20050;
  border-top: 1px solid #dddddd;
  margin-top: 5px;
  padding-top: 8px;
}

.div-scan-count-overall {
  text-align: center;
  border: 1px solid #ccc;
  margin: 4px;
  padding: 10px 5px 10px 5px;
}

.div-scan-count-overall > .scan-count-overall {
  font-size: 32px;
  border-top: 1px solid #38383d;
  margin-top: 20px;
}

.div-scan-count-overall > .reject-count-overall {
  font-size: 14px;
  color: #0444bf;
  border-top: 1px solid #dddddd;
  margin-top: 5px;
  padding-top: 8px;
}

@media only screen and (max-width: 1024px) {
  .dash_table_options_float_right,
  .dash_table_options_float_left {
    float: none;
    text-align: center;
  }

  .search-bar {
    text-align: center;
  }

  .dash_table_inputs {
    width: 100%;
    text-align: center;
  }

  .div-searchbar label {
    width: 100%;
  }
}
